import Vue from 'vue'
import App from './App.vue'
import axios from './api/axios'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import urls from './api/urls'
import './css/hover.css'

import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)

Vue.use(VueFullpage)
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$urls = urls;

Vue.prototype.setCookie = function(name, value) {
  var exp = new Date();
  //定义的失效时间，
  exp.setTime(exp.getTime() + 30 * 24 * 60 * 60 * 1000);
  //写入Cookie  ，toGMTstring将时间转换成字符串。
  document.cookie = name + "=" + value + "" + ";expires=" + exp.toGMTString();
};

Vue.prototype.getCookie = function(key) {
  let ck = document.cookie.replace(/ /g,'');
  let datas = ck.split(";");
  let result = null;
  datas.forEach(item => {
    if (key === item.split("=")[0]) {
      result = item.split("=")[1];
    }
  });
  return result;
};

Vue.prototype.clearCookie = function() {
  let ck = document.cookie.replace(/ /g,'');
  let datas = ck.split(";");
  datas.forEach(item => {
    document.cookie = item.split("=")[0] + "=" + "" + ";expires=" + -1;
  });
};

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

Vue.use(ElementUI);
Vue.use(router);
