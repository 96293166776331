import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

let routes = [
  {
    // 首页
    path: '/',
    // component: () => import('@/components/oAuth/OAuthLogin')
    component: () => import('@/components/FrontPage')
  },
];

// http://host:port/#/xxx
// 这块mode默认是hash, 路径要加# !!!!!!!!!!!!!!!!!!!
const router = new Router({
  routes : routes
});

router.beforeEach(async (to, from, next) => {
  next()
});

export default router
