const api = {
    all: '/user/all',
    test: '/user/test',
    login: '/login',
    user: {
        login: '/user/login',
        getCurrentUser: '/user/getCurrentUser'
    },

    // 第三方登录
    auth: {
        giteeLogin: '/auth/render',
    }
};

export default api;
