import axios from "axios";
import Vue from 'vue'
import { Message } from 'element-ui'

axios.defaults.baseURL = 'http://localhost:6469'; // 测试
// axios.defaults.baseURL = 'http://82.156.195.59:6469'; // 正式

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // config.headers[localStorage.getItem("tokenName")] = localStorage.getItem("tokenValue");
        config.headers["satoken"] = Vue.prototype.getCookie("satoken");
        console.log('tokjen', config.headers)
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status === 200 && response.data.code === 1) {
            return Promise.resolve(response);
        } else {
            Message({
                message: response.data.msg,
                type: 'error'
            });
            return Promise.reject(response);
        }
    },
    error => {
        console.error("请求异常", error);
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: JSON.stringify(data),
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};
